import React from "react";

import { FontWeights, IColumn, ITheme, Stack, Text } from "@bps/fluent-ui";

import { MeasurementRow } from "./ObservationsExpandedTable.tsx";
import { formatMeasurements, ObservationsLabels } from "./utils.ts";

interface ObservationsMeasurementColumnsProps {
  dates: string[];
  groupedByDateAndType: GroupedByDateAndType;
  theme: ITheme;
}

interface GroupedMeasurement {
  id: string;
  value: string;
  timestamp: string;
  type: string;
  summary: string;
}

type GroupedByDateAndType = Record<
  string,
  Record<string, GroupedMeasurement[]>
>;

export interface FormattedMeasurement {
  systolic?: string;
  diastolic?: string;
  label?: string;
  pulse?: string;
  value?: string;
}

export const reverseDateColumns = (cols: IColumn[]) => {
  const dateCols = cols.filter(x => x.key !== "type");
  const typeCol = cols.filter(x => x.key === "type");
  return typeCol.concat(dateCols.reverse());
};

export type FormattedMeasurements = Record<string, FormattedMeasurement>;
const EN_DASH: string = " – ";

export const generateMeasurementColumns = ({
  dates,
  groupedByDateAndType,
  theme
}: ObservationsMeasurementColumnsProps): IColumn[] => {
  const formatTimeWithoutSeconds = (timeString?: string): string => {
    return timeString ? timeString.replace(/:\d{2} /, " ") : "";
  };

  const renderBloodPressure = (
    formattedMeasurements: FormattedMeasurements
  ) => (
    <Stack styles={{ root: { alignSelf: "flex-start" } }}>
      {Object.keys(formattedMeasurements).map(timestamp => {
        const measurement = formattedMeasurements[timestamp];

        return (
          <React.Fragment key={timestamp}>
            <Stack horizontal>
              <Stack>
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      fontWeight: FontWeights.semibold,
                      paddingRight: "5px",
                      color: theme.palette.neutralPrimary
                    }
                  }}
                >
                  {measurement.systolic}/{measurement.diastolic}
                </Text>
              </Stack>
              <Stack>
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      color: theme.palette.neutralPrimaryAlt
                    }
                  }}
                >
                  {EN_DASH}
                  {formatTimeWithoutSeconds(timestamp)}
                </Text>
              </Stack>
            </Stack>
            {measurement.label && (
              <Stack>
                <Text
                  variant="xSmall"
                  styles={{
                    root: {
                      color: theme.palette.neutralPrimaryAlt,
                      paddingBottom: "10px"
                    }
                  }}
                >
                  {measurement.label}
                </Text>
              </Stack>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );

  const renderPulse = (formattedMeasurements: FormattedMeasurements) => (
    <Stack styles={{ root: { alignSelf: "flex-start" } }}>
      {Object.keys(formattedMeasurements).map(timestamp => {
        const measurement = formattedMeasurements[timestamp];
        return (
          <Stack key={timestamp}>
            <Stack horizontal>
              <Stack>
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      fontWeight: FontWeights.semibold,
                      paddingRight: "5px",
                      color: theme.palette.neutralPrimary
                    }
                  }}
                >
                  {measurement.pulse}
                </Text>
              </Stack>
              <Stack>
                <Text
                  variant="medium"
                  styles={{ root: { color: theme.palette.neutralPrimaryAlt } }}
                >
                  {EN_DASH}
                  {formatTimeWithoutSeconds(timestamp)}
                </Text>
              </Stack>
            </Stack>

            {measurement.label && (
              <Stack>
                <Text
                  variant="xSmall"
                  styles={{
                    root: {
                      color: theme.palette.neutralPrimaryAlt,
                      paddingBottom: "10px"
                    }
                  }}
                >
                  {measurement.label}
                </Text>
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  );

  const renderOtherMeasurements = (
    formattedMeasurements: Record<string, FormattedMeasurement>
  ) => (
    <Stack styles={{ root: { alignSelf: "flex-start" } }}>
      {Object.keys(formattedMeasurements).map(timestamp => {
        const measurement = formattedMeasurements[timestamp];
        if (!measurement) {
          return null;
        }
        return (
          <Text
            key={timestamp}
            variant="medium"
            styles={{ root: { fontWeight: FontWeights.semibold } }}
          >
            {measurement.value ? measurement.value : EN_DASH}
          </Text>
        );
      })}
    </Stack>
  );

  return [
    {
      key: "type",
      name: "",
      fieldName: "type",
      minWidth: 150,
      maxWidth: 170,
      isResizable: true,
      onRender: (item: MeasurementRow) => {
        return (
          <Stack styles={{ root: { alignSelf: "flex-start" } }}>
            <Text
              styles={{
                root: {
                  color: theme.palette.neutralPrimary
                }
              }}
              variant="medium"
            >
              {item.type}
            </Text>
          </Stack>
        );
      }
    },
    ...dates.map(date => ({
      key: date,
      name: date,
      fieldName: date,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: MeasurementRow) => {
        const measurementsForTypeOnDate =
          groupedByDateAndType[date]?.[item.type] || [];

        if (measurementsForTypeOnDate.length > 0) {
          const formattedMeasurements: FormattedMeasurements =
            formatMeasurements(measurementsForTypeOnDate);
          switch (item.type) {
            case ObservationsLabels.BloodPressure:
              return renderBloodPressure(formattedMeasurements);
            case ObservationsLabels.PulseBpm:
              return renderPulse(formattedMeasurements);
            default:
              return renderOtherMeasurements(formattedMeasurements);
          }
        }
        return EN_DASH;
      }
    }))
  ];
};
