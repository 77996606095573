import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { CheckboxVisibility, NoDataTile, SelectionMode } from "@bps/fluent-ui";
import { isPending } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalTaskFilterValues } from "@shared-types/clinical/clinical-task-filter-values.type.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { ClinicalTaskDialog } from "./ClinicalTaskDialog.tsx";
import { defaultFilters } from "./ClinicalTaskList.tsx";
import { ClinicalTaskTable } from "./ClinicalTaskTable.tsx";
import { PatientClinicalTaskFormModel } from "./PatientClinicalTaskFormModel.ts";

export interface ClinicalTaskTableBaseProps {
  compact: boolean;
  onAddClinicalTaskClick?: () => void;
  showLockIcon: boolean;
  removeHeader?: boolean;
  setSelected?: React.Dispatch<React.SetStateAction<ClinicalTask[]>>;
}

export interface ClinicalTaskTableWrapperProps
  extends ClinicalTaskTableBaseProps {
  clinicalTask?: ClinicalTask;
  showClinicalTaskDialog: boolean;
  clinicalTaskDialogOnDismiss: () => void;
  clinicalRecord: ClinicalRecord;
  filter?: ClinicalTaskFilterValues;
  verticalView?: boolean;
}

export const ClinicalTaskTableWrapper: React.FC<ClinicalTaskTableWrapperProps> =
  observer(
    ({
      clinicalRecord,
      compact,
      showClinicalTaskDialog,
      clinicalTaskDialogOnDismiss,
      onAddClinicalTaskClick,
      clinicalTask,
      showLockIcon,
      removeHeader,
      filter,
      setSelected,
      verticalView
    }) => {
      useEffect(() => {
        // Fetch patientClinicalTasks here is not duplicated action in ClinicalHeader.
        // Since we use this component in Tasks section we need to have independent fetch.
        !isPending(clinicalRecord.patientClinicalTasksPromise) &&
          !clinicalRecord.patientClinicalTasksPromise.hasPromise &&
          clinicalRecord.setPatientClinicalTasksPromise();
      }, [clinicalRecord]);

      const { clinical } = useStores();

      const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
      const clinicalTaskFormModel = new PatientClinicalTaskFormModel(
        clinicalRecord,
        clinicalTask
      );

      const tasks = clinicalRecord.getFilteredClinicalTasks(
        filter ?? defaultFilters
      );

      const errorMessage =
        clinicalRecord.patientClinicalTasksPromise.error?.message;

      const pending = clinicalRecord.patientClinicalTasksPromise.pending;

      return (
        <>
          <ClinicalTaskTable
            setSelected={setSelected}
            compact={compact}
            onAddClinicalTaskClick={onAddClinicalTaskClick}
            showLockIcon={showLockIcon}
            isViewOnly={
              isViewOnlyOrDischarged || clinical.ui.clinicalTaskActionDisabled
            }
            verticalView={verticalView}
          >
            {({ selection, columns, renderRow, renderDetailsHeader }) => (
              <>
                {tasks.length > 0 || errorMessage || pending ? (
                  <ShimmeredDetailsList
                    setKey="clinical-task-table"
                    stickyHeader
                    enableShimmer={
                      clinicalRecord.patientClinicalTasksPromise.pending
                    }
                    errorMessage={
                      clinicalRecord.patientClinicalTasksPromise.error?.message
                    }
                    detailsListStyles={{
                      root: {
                        overflowY: compact ? "hidden" : "auto",
                        height: "100%"
                      }
                    }}
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={
                      compact || isViewOnlyOrDischarged
                        ? SelectionMode.none
                        : SelectionMode.multiple
                    }
                    selection={selection.current}
                    selectionPreservedOnEmptyClick={true}
                    columns={columns}
                    items={clinicalRecord.getFilteredClinicalTasks(
                      filter ?? defaultFilters
                    )}
                    onRenderRow={renderRow}
                    onRenderDetailsHeader={renderDetailsHeader}
                    isHeaderVisible={!removeHeader}
                  />
                ) : (
                  <NoDataTile
                    textProps={{ text: "No information to display" }}
                    linkProps={
                      compact &&
                      !isViewOnlyOrDischarged &&
                      !clinical.ui.clinicalTaskActionDisabled
                        ? {
                            text: "Add clinical task",
                            onClick: onAddClinicalTaskClick
                          }
                        : { hidden: true }
                    }
                    showBoxShadow={false}
                    styles={{
                      root: {
                        padding: 0,
                        minHeight: compact ? 75 : 120,
                        width: "100%",
                        height: "100%"
                      }
                    }}
                  />
                )}
              </>
            )}
          </ClinicalTaskTable>
          <ClinicalTaskDialog
            hidden={!showClinicalTaskDialog}
            clinicalTasks={clinicalRecord?.clinicalTasks}
            clinicalTask={clinicalTask}
            onDismiss={clinicalTaskDialogOnDismiss}
            initialValues={clinicalTaskFormModel.initialValues}
            onSubmit={clinicalTaskFormModel.onSubmit}
            clinicalRecordId={clinicalRecord.id}
          />
        </>
      );
    }
  );
