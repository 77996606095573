import { Observer, observer } from "mobx-react-lite";
import { FC, useCallback, useContext, useRef } from "react";

import {
  DetailsRow,
  DetailsRowFields,
  FontIcon,
  FontSizes,
  Heading,
  IColumn,
  IDetailsRowFieldsProps,
  IDetailsRowProps,
  Link,
  NoDataTile,
  ScrollablePane,
  Selection,
  SelectionMode,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useFormContext,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Outcome } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  InboxTasksFilterValues,
  UserTaskStatus
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { CorrespondencesLabels } from "@modules/clinical/screens/patient-record/components/correspondence/Correspondences.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserTask } from "@stores/inbox/models/UserTask.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { ItalicCenterText } from "@ui-components/ItalicCenterText.tsx";
import { hideUnlessPrintingStyles } from "@ui-components/printing/Print.styles.ts";
import { PrintContentWrapper } from "@ui-components/printing/PrintContentWrapper.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { TasksScreenLabels } from "./Tasks.types.ts";

export const FollowUpTaskList: FC = observer(() => {
  const {
    state: { dirty, values: filter },
    actions: { reset }
  } = useFormContext<InboxTasksFilterValues>();

  const {
    practice: {
      ui: { showContactDetails }
    },
    core,
    inbox,
    userExperience
  } = useStores();

  const theme = useTheme();
  const selectedTask = useRef<string | undefined>();
  const {
    setTaskId,
    setActiveForm,
    setInboxDocument,
    getFollowupUserTasks,
    updateSelectionOnSearch
  } = useContext(InboxScreenContext);

  const updateUserTaskEtag = inbox.ui.userTaskRefreshKey;

  const ifEmpty = (textToDisplay: string = "No data to display") => {
    return (
      <Text
        styles={(_props, { palette }) => ({
          root: { fontStyle: "italic", color: palette.neutralTertiary }
        })}
      >
        {textToDisplay}
      </Text>
    );
  };

  const renderOutcome = (userTask: UserTask) => {
    let outcomeLabelText = userTask.latestOutcomeStatusFromLog;
    if (outcomeLabelText) {
      switch (outcomeLabelText) {
        case Outcome.AttemptedContact:
          outcomeLabelText = CorrespondencesLabels.ContextMenuAttemptedContact;
          break;
        case Outcome.Informed:
          outcomeLabelText = CorrespondencesLabels.ContextMenuInformed;
          break;
        case Outcome.Booked:
          outcomeLabelText = CorrespondencesLabels.ContextMenuBooked;
          break;
        case Outcome.Given:
          outcomeLabelText = CorrespondencesLabels.ContextMenuGiven;
          break;
        case Outcome.None:
          outcomeLabelText = CorrespondencesLabels.ContextMenuNone;
          break;
      }
    }

    return <Observer>{() => <Text>{outcomeLabelText}</Text>}</Observer>;
  };

  const renderFollowupDetails = (userTask: UserTask) => {
    let badgeLabel = "";
    let badgeColor = TextBadgeColor.lightGrey;
    const dueDate = userTask.dueDateTime
      ? userTask.dueDateTime.toDayDefaultFormat()
      : "";

    const dueDateLabelText = userTask.dueDateTime && `Due: ${dueDate}`;
    switch (userTask.subject) {
      case TasksScreenLabels.UrgentAppointment:
        badgeLabel = TasksScreenLabels.UrgentBadgeLabel;
        badgeColor = TextBadgeColor.red;
        break;
      case TasksScreenLabels.NonUrgentAppointment:
        badgeLabel = TasksScreenLabels.NonUrgentBadgeLabel;
        badgeColor = TextBadgeColor.yellow;
        break;
      case TasksScreenLabels.NoAction:
        badgeLabel = TasksScreenLabels.NoAction;
        badgeColor = TextBadgeColor.lightGrey;
        break;
    }

    return (
      <Stack tokens={{ childrenGap: 4 }}>
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={badgeColor}
          styles={{
            root: {
              alignSelf: "start"
            }
          }}
        >
          {badgeLabel}
        </TextBadge>
        <Text
          styles={{
            root: {
              fontSize: FontSizes.size12,
              color: theme.palette.neutralPrimaryAlt
            }
          }}
        >
          {dueDateLabelText}
        </Text>
      </Stack>
    );
  };

  const renderChecked = (userTask: UserTask) => {
    const checkedDate = userTask.checkedDateTime?.toDayDefaultFormat() || "Nil";

    const checkedBy =
      userTask.checkedByUser?.name && `by ${userTask.checkedByUser?.name}`;

    const checkedToolTipText = `${checkedDate} ${checkedBy}`;

    return (
      <Observer>
        {() => (
          <TooltipHost content={checkedToolTipText}>
            <Text
              styles={{
                root: {
                  fontSize: FontSizes.size12,
                  color: theme.palette.neutralPrimaryAlt
                }
              }}
            >
              {checkedBy}
            </Text>
            <Stack>
              <Text styles={{ root: { fontSize: FontSizes.size14 } }}>
                {checkedDate}
              </Text>
            </Stack>
          </TooltipHost>
        )}
      </Observer>
    );
  };

  const renderStatus = (item: UserTask) => {
    return (
      <Observer>
        {() => {
          if (item.status === UserTaskStatus.New) {
            return (
              <TooltipHost
                content="New"
                tooltipProps={{ calloutProps: { gapSpace: -13 } }}
              >
                <FontIcon
                  iconName="CheckedOutByOther12"
                  styles={{
                    root: {
                      color: theme.palette.themePrimary,
                      fontSize: FontSizes.size16,
                      display: "block",
                      textAlign: "center"
                    }
                  }}
                />
              </TooltipHost>
            );
          } else if (item.status === UserTaskStatus.InProgress) {
            return (
              <TooltipHost
                content="In progress"
                tooltipProps={{ calloutProps: { gapSpace: -13 } }}
              >
                <FontIcon
                  iconName="HourGlass"
                  styles={{
                    root: {
                      color: theme.palette.neutralSecondary,
                      fontSize: FontSizes.size16,
                      display: "block",
                      textAlign: "center"
                    }
                  }}
                />
              </TooltipHost>
            );
          } else if (item.status === UserTaskStatus.Completed) {
            return (
              <TooltipHost
                content="Completed"
                tooltipProps={{ calloutProps: { gapSpace: -13 } }}
              >
                <FontIcon
                  iconName="Accept"
                  styles={{
                    root: {
                      color: theme.semanticColors.successIcon,
                      fontSize: FontSizes.size16,
                      display: "block",
                      textAlign: "center"
                    }
                  }}
                />
              </TooltipHost>
            );
          }
          return null;
        }}
      </Observer>
    );
  };

  const patientLabel = userExperience.localisedConfig("patientDisplay");

  const columns: IColumn[] = [
    {
      key: "status",
      minWidth: 10,
      maxWidth: 10,
      name: "",
      onRender: renderStatus
    },
    {
      key: "assignee",
      minWidth: 150,
      maxWidth: 150,
      name: TasksScreenLabels.AssignedTo,
      onRender: (item: UserTask) => (
        <Observer>
          {() =>
            item.user ? (
              <Text>{item.user.fullName}</Text>
            ) : (
              ifEmpty(TasksScreenLabels.Unassigned)
            )
          }
        </Observer>
      )
    },
    {
      key: "patient",
      minWidth: 150,
      maxWidth: 170,
      name: capitalizeSentence(patientLabel),
      onRender: (item: UserTask) => (
        <Observer>
          {() =>
            item.patient ? (
              <Link
                onClick={() => {
                  if (item.patient) {
                    showContactDetails(item.patient.id);
                  }
                }}
              >
                {item.patient.name}
              </Link>
            ) : (
              ifEmpty()
            )
          }
        </Observer>
      )
    },
    {
      key: "follow-up",
      minWidth: 110,
      maxWidth: 110,
      name: TasksScreenLabels.FollowUp,
      onRender: renderFollowupDetails
    },
    {
      key: "outcome",
      minWidth: 120,
      maxWidth: 120,
      isMultiline: true,
      name: TasksScreenLabels.Outcome,
      onRender: renderOutcome
    },
    {
      key: "checkedDateTime",
      minWidth: 120,
      maxWidth: 120,
      name: TasksScreenLabels.Checked,
      onRender: renderChecked
    },
    {
      key: "extraInfo",
      minWidth: 130,
      maxWidth: 300,
      isMultiline: true,
      name: TasksScreenLabels.ExtraInfo,
      onRender: (userTask: UserTask) => (
        <Observer>
          {() => <Text>{commentText(userTask.extraInfo)}</Text>}
        </Observer>
      )
    }
  ];

  const dateOfPrint = DateTime.now().toDayDefaultFormat();

  const commentText = (comment?: string) => {
    return <Text>{comment}</Text>;
  };

  const selection = useRef(
    new Selection({
      onSelectionChanged: async () => {
        const indexes = selection.current.getSelectedIndices();
        const itemKey = indexes[0];
        const item = selection.current.getItems()[itemKey] as UserTask;

        if (!itemKey) {
          selectedTask.current = undefined;
          setTaskId();
          setActiveForm(false);
          setInboxDocument();
        }
        if (item) {
          selectedTask.current = item.userActionId;
          setActiveForm(false);
          setTaskId(item.id);
          setInboxDocument();
        }
        return;
      }
    })
  );

  const renderRow = (props: IDetailsRowProps): JSX.Element => {
    return (
      <DetailsRow
        {...props}
        styles={{
          root: {
            "&.is-selected:hover": {
              backgroundColor: theme.semanticColors.listItemBackgroundChecked
            }
          }
        }}
        rowFieldsAs={renderRowFields}
      />
    );
  };

  const renderRowFields = (props: IDetailsRowFieldsProps) => {
    return (
      <Stack verticalAlign="center">
        <DetailsRowFields {...props} />
      </Stack>
    );
  };

  const search = useCallback(
    async (query: PagingOptions) => {
      const userTasks = await getFollowupUserTasks({
        query,
        filter,
        userId: core?.user?.id
      });

      updateSelectionOnSearch(selection.current);

      return userTasks;
    },
    [getFollowupUserTasks, filter, core?.user?.id, updateSelectionOnSearch]
  );

  const onRenderNoResults = () =>
    dirty ? (
      //position: "absolute" is used here because otherwise the resetFilters link of <NoSearchResult> will not be clickable
      //as <ScrollablePane> also has position absolute;
      <Stack
        horizontal
        styles={{
          root: {
            height: "100%",
            width: "100%",
            position: "absolute",
            paddingTop: 50
          }
        }}
      >
        <NoDataTile
          styles={{ root: { width: "100%", height: "100%" } }}
          textProps={{ text: "0 matches found" }}
          linkProps={{
            text: "Clear filters",
            onClick: reset
          }}
          showBoxShadow={false}
        />
      </Stack>
    ) : (
      <ItalicCenterText>{TasksScreenLabels.NoTasks}</ItalicCenterText>
    );

  return (
    <div style={{ position: "relative", flexGrow: 1 }} id="task-list-table">
      <ScrollablePane>
        <PrintContentWrapper hidePrintContent={false}>
          <Heading variant="section-heading" styles={hideUnlessPrintingStyles}>
            {TasksScreenLabels.Tasks}
          </Heading>
          {/*print-page-break class prevents long lists' breaking between pages*/}
          <div className="print-page-break" />
          <Stack verticalAlign="space-between">
            <InfiniteScrollList<UserTask>
              getItems={search}
              refreshKey={updateUserTaskEtag}
              selectionMode={SelectionMode.single}
              selection={selection.current}
              selectionPreservedOnEmptyClick={true}
              onRenderRow={renderRow}
              columns={columns}
              setKey="task-list"
              onRenderNoResults={onRenderNoResults}
            />
            <Stack className="print-footer" styles={hideUnlessPrintingStyles}>
              <Text block>
                {`${TasksScreenLabels.DatePrinted}: ${dateOfPrint}`}{" "}
              </Text>
              <Text block>
                {`${TasksScreenLabels.PrintedBy}: ${core.user?.name}`}
              </Text>
            </Stack>
          </Stack>
        </PrintContentWrapper>
      </ScrollablePane>
    </div>
  );
});
