import { observer } from "mobx-react-lite";

import {
  CheckboxVisibility,
  dataAttribute,
  DataAttributes,
  DetailsList,
  IColumn,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  MedicalHistoryClinicalDataItemDto,
  MedicalHistoryTestElements
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TerminologyText } from "@modules/clinical/screens/patient-record/components/TerminologyText.tsx";

export interface MedicalHistoryTableProps {
  medicalHistories: MedicalHistoryClinicalDataItemDto[];
}

const getDate = (diagnosisDate: string | undefined) => {
  if (!diagnosisDate) {
    return "N/A";
  }

  const [year, month, day] = diagnosisDate.split("-");
  let date = year;
  if (day) {
    date = `${day}/${month}/${year}`;
  } else if (month) {
    date = `${month}/${year}`;
  }
  return date;
};

export const MedicalHistoryTable: React.FunctionComponent<MedicalHistoryTableProps> =
  observer(({ medicalHistories }) => {
    const columns: IColumn[] = [
      {
        key: "diagnosisDate",
        name: "Date",
        minWidth: 80,
        maxWidth: 80,
        onRender: item => {
          return (
            <Text
              styles={{ root: { paddingBottom: 4 } }}
              {...dataAttribute(
                DataAttributes.Element,
                MedicalHistoryTestElements.DateColumn
              )}
            >
              {getDate(item.diagnosisDate)}
            </Text>
          );
        }
      },
      {
        key: "details",
        name: "Details",
        minWidth: 100,
        isMultiline: true,
        onRender: item => {
          return (
            <Text
              {...dataAttribute(
                DataAttributes.Element,
                MedicalHistoryTestElements.DiagnosisColumn
              )}
            >
              <TerminologyText
                codedTexts={[
                  {
                    code: item.diagnosisCode?.code,
                    text: item.diagnosisCode?.originalText
                  }
                ]}
              />
            </Text>
          );
        }
      }
    ];
    return (
      <Stack
        styles={{
          root: {
            overflowY: "auto"
          }
        }}
      >
        {medicalHistories && (
          <DetailsList
            setKey="medicalHistory"
            compact
            columns={columns}
            items={medicalHistories || []}
            checkboxVisibility={CheckboxVisibility.hidden}
            cellStyleProps={{
              cellExtraRightPadding: 24,
              cellRightPadding: 8,
              cellLeftPadding: 2
            }}
          />
        )}
      </Stack>
    );
  });
