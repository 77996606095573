import { Heading, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  TransactionDto,
  TransactionItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { RecordUpdateCheckedLog } from "@modules/clinical/screens/shared-components/RecordUpdateCheckedLog.tsx";
import { Transaction } from "@stores/billing/models/Transaction.ts";

interface TransactionInfoColumnProps {
  transaction: Transaction<
    TransactionDto<TransactionItemDto>,
    TransactionItemDto
  >;
}

export const TransactionInfoColumn: React.FC<TransactionInfoColumnProps> = ({
  transaction
}) => (
  <Stack tokens={{ childrenGap: 8 }}>
    <Stack horizontal tokens={{ childrenGap: 24 }}>
      <Stack.Item>
        <Heading labelPaddings>Date</Heading>
        <Text>
          {DateTime.fromJSDate(
            transaction.transactionDate
          ).toDayDefaultFormat()}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Heading labelPaddings>User</Heading>

        <RecordUpdateCheckedLog
          showNameOnly
          createdBy={transaction.changeLog?.createdBy}
        />
      </Stack.Item>
    </Stack>
    <div>
      <Heading labelPaddings>Comments</Heading>
      <Text>{transaction.comment}</Text>
    </div>
  </Stack>
);
