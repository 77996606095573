import { observer } from "mobx-react-lite";
import React from "react";

import {
  FontIcon,
  FontSizes,
  NoDataTile,
  NoDataTileProps,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  ClinicalActivityDescriptionCode,
  ClinicalActivityType
} from "./types/clinical-activity.type.ts";

export interface ClinicalActivitySideTableProps {
  clinicalRecord: ClinicalRecord;
  noDataTileStyles?: NoDataTileProps["styles"];
  greyBackground?: boolean;
  noDataMessage?: string;
  showDialogue: () => void;
}

export const ClinicalActivitySideTableBase: React.FC<ClinicalActivitySideTableProps> =
  observer(
    ({
      clinicalRecord,
      noDataTileStyles,
      noDataMessage,
      greyBackground,
      showDialogue
    }) => {
      const { isViewOnly } = usePatientRecordScreenContext();
      const theme = useTheme();
      const { clinical, core, comms } = useStores();

      const activities = clinicalRecord.clinicalActivities.filter(
        x => !x.isCompleted && !x.isDeleted
      );

      const activitiesCount = activities.length;
      const clinicalReminderReasons =
        comms.clinicalReminderPreference?.clinicalReminderReasons;

      const renderStatusBadge = (item: ClinicalActivity) => {
        const activityStatus = item.activityStatus;
        let badgeColor = TextBadgeColor.lightGrey;
        if (activityStatus === ClinicalActivityStatusText.Upcoming) {
          badgeColor = TextBadgeColor.blue;
        }
        if (activityStatus === ClinicalActivityStatusText.Today) {
          badgeColor = TextBadgeColor.yellow;
        }
        if (activityStatus === ClinicalActivityStatusText.Overdue) {
          badgeColor = TextBadgeColor.red;
        }

        return (
          <TextBadge
            badgeColor={badgeColor}
            badgeSize={TextBadgeSize.medium}
            hasBorder={activityStatus === ClinicalActivityStatusText.Today}
            styles={{
              root: {
                fontSize: FontSizes.size12
              }
            }}
          >
            {activityStatus}
          </TextBadge>
        );
      };

      const iconType = (item: ClinicalActivity) => {
        let onHoverText = "Clinical task";
        let fontIcon = "ClipboardList";

        if (item.activityType === ClinicalActivityType.Reminder) {
          fontIcon = "ReminderTime";
          onHoverText = "Clinical reminder";
        }

        return (
          <TooltipHost content={onHoverText}>
            <FontIcon iconName={fontIcon} />
          </TooltipHost>
        );
      };

      const renderItem = () => {
        return activities.map(activity => {
          if (!activity.descriptionCode) {
            return undefined;
          }

          const activityDescription =
            clinical.ref.clinicalActivityDescriptions.get(
              activity.descriptionCode
            );

          const clinicalReminderReason = clinicalReminderReasons?.find(
            x => x.reason === activity.descriptionCode
          );

          const descriptionText =
            activityDescription?.text ?? activity.descriptionCode;

          return (
            <Stack
              key={activity.id}
              styles={{
                root: {
                  borderBottom: `1px solid ${theme.palette.themeLighter}`,
                  padding: 8
                }
              }}
              tokens={{ childrenGap: 8 }}
              horizontal
              verticalAlign="center"
            >
              {iconType(activity)}
              {renderStatusBadge(activity)}

              <Text>
                {activity.descriptionCode ===
                ClinicalActivityDescriptionCode.Other
                  ? activity.freeText
                  : descriptionText}
              </Text>

              {clinicalReminderReason?.clinicallySignificant && (
                <TextBadge
                  badgeColor={TextBadgeColor.blue}
                  badgeSize={TextBadgeSize.small}
                >
                  <FontIcon
                    iconName="UserOptional"
                    styles={{
                      root: {
                        fontSize: FontSizes.size16,

                        verticalAlign: "middle"
                      }
                    }}
                  />
                </TextBadge>
              )}
            </Stack>
          );
        });
      };

      if (activitiesCount === 0) {
        return (
          <NoDataTile
            styles={{
              root: {
                padding: 0,
                minHeight: 120,
                width: "100%",
                height: "100%"
              },
              ...noDataTileStyles
            }}
            greyView={greyBackground}
            textProps={{ text: noDataMessage ?? "" }}
            linkProps={{
              text: "Add activity",
              hidden:
                isViewOnly ||
                !core.hasPermissions(Permission.ClinActivityWrite) ||
                clinical.ui.clinicalActivityActionDisabled,
              onClick: () => {
                showDialogue();
              }
            }}
            showBoxShadow={false}
          />
        );
      }

      return <>{renderItem()}</>;
    }
  );

export const ClinicalActivitySideTable = withFetch(
  x => [
    x.clinical.ref.clinicalActivityTypes.load(),
    x.clinical.ref.clinicalActivityDescriptions.load(),
    x.comms.loadClinicalReminderPreferenceByTenant()
  ],
  ClinicalActivitySideTableBase
);
