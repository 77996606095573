import React, { useCallback } from "react";

import {
  CheckboxVisibility,
  IColumn,
  NoDataTile,
  ScrollablePane,
  SelectionMode,
  TooltipHost
} from "@bps/fluent-ui";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { usePatientMatchContext } from "../context/PatientMatchContext.ts";
import {
  PatientMatchFilter,
  PatientMatchListItem
} from "./PatientMatchListItem.types.ts";
import { PatientMatchNameColumn } from "./PatientMatchNameColumn.tsx";

export const PatientMatchSearch: React.FC<{
  filter: PatientMatchFilter;
}> = ({ filter }) => {
  const helper = usePatientMatchContext();
  const patientLabel = usePatientLabel(false);
  const MINHEIGHT = 153;

  const renderName = (patientMatchListItem: PatientMatchListItem) => {
    return <PatientMatchNameColumn contact={patientMatchListItem.contact} />;
  };

  const columns: IColumn[] = [
    {
      name: "Name",
      key: nameof<PatientMatchListItem>("name"),
      onRender: renderName,
      minWidth: 80,
      maxWidth: 150
    },
    {
      name: "Date of birth",
      fieldName: nameof<PatientMatchListItem>("birthDate"),
      key: nameof<PatientMatchListItem>("birthDate"),
      minWidth: 80,
      maxWidth: 100
    },
    {
      name: "Preferred phone",
      fieldName: nameof<PatientMatchListItem>("preferredPhone"),
      key: nameof<PatientMatchListItem>("preferredPhone"),
      minWidth: 130,
      maxWidth: 130
    },
    {
      name: "Email",
      fieldName: nameof<PatientMatchListItem>("email"),
      key: nameof<PatientMatchListItem>("email"),
      minWidth: 80,
      maxWidth: 130
    },
    {
      name: "Address",
      fieldName: nameof<PatientMatchListItem>("address"),
      key: nameof<PatientMatchListItem>("address"),
      minWidth: 80,
      maxWidth: 250,
      onRender: (item: PatientMatchListItem) => {
        return <TooltipHost content={item.address}>{item.address}</TooltipHost>;
      }
    }
  ];

  const getItems = useCallback(
    (query: PagingOptions) => {
      return helper.patientSearch(query, filter);
    },
    [helper, filter]
  );

  // Effectively checking if its the first load
  if ((filter.search?.length ?? 0) === 0) {
    return (
      <NoDataTile
        textProps={{ text: `Search for an existing ${patientLabel} record` }}
        linkProps={{ hidden: true }}
        styles={{ root: { minHeight: MINHEIGHT } }}
      />
    );
  }

  return (
    <div style={{ position: "relative", minHeight: MINHEIGHT }}>
      <ScrollablePane styles={{ root: { overflow: "hidden" } }}>
        <InfiniteScrollList
          columns={columns}
          getItems={getItems}
          selectionMode={SelectionMode.single}
          checkboxVisibility={CheckboxVisibility.always}
          selection={helper.selection}
          onRenderNoResults={() => (
            <NoDataTile
              textProps={{
                text: `No matches for "${filter.search}"`
              }}
              linkProps={{
                text: `Create a new ${patientLabel}`,
                onClick: () => helper.onCreateNewPatientClick()
              }}
            />
          )}
        />
      </ScrollablePane>
    </div>
  );
};
