import { observer } from "mobx-react-lite";
import React from "react";

import {
  FontSizes,
  Link,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

import { ErrorMessageWithAction } from "./types/error-message-with-action.interface.ts";

interface ErrorMessageBarProps {
  errorMessageList: ErrorMessageWithAction[];
}

export const ErrorMessageBar: React.FC<ErrorMessageBarProps> = observer(
  ({ errorMessageList }) => {
    return (
      <>
        {errorMessageList?.map(m => (
          <>
            {!m.hidden && (
              <MessageBar
                messageBarType={MessageBarType.error}
                key={`claimError${m.message}`}
              >
                <Stack>
                  <div>
                    {m.message}
                    {m.action && (
                      <>
                        {" "}
                        <Link
                          onClick={m.action}
                          styles={{
                            root: { fontSize: 12 }
                          }}
                        >
                          Clear error to relodge
                        </Link>
                      </>
                    )}
                  </div>
                  {m.field && (
                    <Stack.Item
                      styles={{ root: { fontSize: FontSizes.size12 } }}
                    >{`Field: ${m.field}`}</Stack.Item>
                  )}
                  {m.details && (
                    <>
                      <br />
                      <Stack.Item
                        styles={{ root: { fontSize: FontSizes.size12 } }}
                      >
                        {m.details}
                      </Stack.Item>
                    </>
                  )}
                  {m.lastRequestedDateTime && (
                    <>
                      <br />
                      <Stack.Item
                        styles={{ root: { fontSize: FontSizes.size12 } }}
                      >
                        Status last requested on:{" "}
                        {DateTime.fromISO(m.lastRequestedDateTime).toFormat(
                          DATE_FORMATS.DETAILED_DATE_TIME
                        )}
                      </Stack.Item>
                    </>
                  )}
                  {m.nextRequestDateTime && (
                    <>
                      <br />
                      <Stack.Item
                        styles={{ root: { fontSize: FontSizes.size12 } }}
                      >
                        Next retry scheduled for:{" "}
                        {DateTime.fromISO(m.nextRequestDateTime).toFormat(
                          DATE_FORMATS.DETAILED_DATE_TIME
                        )}
                      </Stack.Item>
                    </>
                  )}
                </Stack>
              </MessageBar>
            )}
          </>
        ))}
      </>
    );
  }
);
