import { observer } from "mobx-react-lite";
import { FC, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  Spinner,
  Stack,
  Text,
  useScreenSize
} from "@bps/fluent-ui";
import {
  ClinicalDataType,
  EncounterType,
  PatientSummary as PatientSummaryEnum,
  TreeView
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { patientSummaryIcons } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { PatientNoticeCallout } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticeCallout.tsx";
import { NoticeType } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesModel.ts";
import { PatientNoticesTable } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesTable.tsx";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalTaskTableWrapper } from "../clinical-task/ClinicalTaskTableWrapper.tsx";
import { ClinicalToolCardComponent } from "./ClinicalToolContent.tsx";
import { FamilySocialCardComponent } from "./FamilySocialContent.tsx";
import { usePatientSummaryContext } from "./helpers/PatientSummaryContext.tsx";
import { MedicalHistoryCardComponent } from "./MedicalHistoryContent.tsx";
import { CurrentRxCardComponent } from "./MedicationContent.tsx";
import {
  PatientSummaryCard,
  PatientSummaryCardInfo
} from "./PatientSummaryCard.tsx";
import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";
import {
  getLatestEncounters,
  getSortedEncounters,
  PreEncounterNotesContent,
  PreviousEncounterNotes
} from "./PreEncounterNotesContent.tsx";
import { RecentIncomingDocComponent } from "./RecentIncomingDocContent.tsx";
import { RFVCard } from "./RFVContent.tsx";

interface PatientSummaryBaseProps {
  clinicalRecord: ClinicalRecord;
}

const PatientSummaryBase: FC<PatientSummaryBaseProps> = observer(
  (props: PatientSummaryBaseProps) => {
    const root = useStores();
    const { userExperience, clinical, core, correspondence } = root;
    const { searchIncomingDocs } = usePatientSummaryContext();

    const { clinicalRecord } = props;
    const { width } = useScreenSize();
    const { isViewOnly, selectedPreviousEncounterId } =
      usePatientRecordScreenContext();

    const encounters = clinicalRecord.closedEncounters?.filter(
      encounter => encounter.type !== EncounterType.RecordUpdate
    );

    const lastConsultDate = encounters[0]?.startDateTime;
    // Use object state to handle multiple dialogs
    const [isDialogVisible, setDialogVisible] = useState({
      isClinicalTaskDialogVisible: false
    });

    const handleDialogVisible = (
      visible: boolean,
      summaryKey: PatientSummaryEnum
    ) => {
      switch (summaryKey) {
        case PatientSummaryEnum.ClinicalTasks:
          setDialogVisible({
            ...isDialogVisible,
            isClinicalTaskDialogVisible: visible
          });
          break;
      }
    };

    const getContent = (summary: PatientSummaryCardInfo): JSX.Element => {
      const newSummary: PatientSummaryCardInfo = { ...summary };
      const iName = patientSummaryIcons.find(p => p.key === summary.key)
        ?.iconName;

      newSummary.iconName = iName ? iName : "";
      // Each case statement should have two parts.
      // A get content function to determine if there is any content to render
      // If there is content to render then render the component
      // Otherwise return undefined
      // This makes the empty state generic
      switch (summary.key) {
        case PatientSummaryEnum.MedicalHistory:
          newSummary.content = (
            <MedicalHistoryCardComponent clinicalRecord={clinicalRecord} />
          );
          newSummary.onClickLink = () => {
            if (clinical.ui.tabs.currentPatientRecordTab)
              clinical.ui.tabs.currentPatientRecordTab.sidePanelTab =
                TreeView.MedicalHistory;
          };
          break;
        case PatientSummaryEnum.ReasonForVisit:
          newSummary.content = (
            <RFVCard
              encounters={clinicalRecord.closedEncounters?.filter(
                encounter =>
                  encounter.type !== EncounterType.RecordUpdate &&
                  core.hasAccessToSecGroup(encounter.secGroupId)
              )}
              currentPatientRecordTab={
                clinical.ui.tabs.currentPatientRecordTab!
              }
            />
          );
          newSummary.onClickLink = () => {
            if (clinical.ui.tabs.currentPatientRecordTab)
              clinical.ui.tabs.currentPatientRecordTab.sidePanelTab =
                TreeView.PastConsults;
          };
          break;
        case PatientSummaryEnum.ClinicalTasks:
          newSummary.columnSpan = 2;
          newSummary.content = (
            <ClinicalTaskTableWrapper
              clinicalRecord={clinicalRecord}
              compact={true}
              showLockIcon={false}
              showClinicalTaskDialog={
                isDialogVisible.isClinicalTaskDialogVisible
              }
              clinicalTaskDialogOnDismiss={() =>
                handleDialogVisible(false, PatientSummaryEnum.ClinicalTasks)
              }
              onAddClinicalTaskClick={() =>
                handleDialogVisible(true, PatientSummaryEnum.ClinicalTasks)
              }
            />
          );
          newSummary.onClickLink = () => {
            clinical.ui.setPatientClinicalContent({
              type: ClinicalDataType.ClinicalTask
            });
          };
          break;
        case PatientSummaryEnum.FamilySocial:
          newSummary.content = (
            <FamilySocialCardComponent
              clinicalRecord={clinicalRecord}
              userExperience={userExperience}
            />
          );

          newSummary.onClickLink = () => {
            if (clinical.ui.tabs.currentPatientRecordTab)
              clinical.ui.tabs.currentPatientRecordTab.sidePanelTab =
                TreeView.FamilyAndSocialHistory;
          };
          break;
        case PatientSummaryEnum.Medication:
          newSummary.content = (
            <CurrentRxCardComponent clinicalRecord={clinicalRecord} />
          );
          break;
        case PatientSummaryEnum.NotesFromPreviousEncounter:
          const encounters = getSortedEncounters(
            clinicalRecord.closedEncounters
          );

          const selectedEncounter = encounters.find(
            x => x.id === selectedPreviousEncounterId
          );

          newSummary.content = (
            <DataFetcher<PreviousEncounterNotes[]>
              fetch={() =>
                getLatestEncounters({
                  encounters,
                  core,
                  user: core.user,
                  showOnlyMyNotes: userExperience?.settings.onlyShowMyNotes,
                  clinicalRecord,
                  clinical
                })
              }
              fallback={<Spinner />}
            >
              {encounterNotes =>
                encounterNotes.length > 0 ? (
                  <PreEncounterNotesContent content={encounterNotes} />
                ) : (
                  <PatientSummaryEmptyState />
                )
              }
            </DataFetcher>
          );

          newSummary.onClickLink = () => {
            if (encounters.length > 0) {
              if (
                clinical.ui.tabs.currentPatientRecordTab?.state
                  .sidePanelSize === TreeViewSize.IconsOnly
              ) {
                clinical.ui.tabs.currentPatientRecordTab.toggleSidePanel(
                  true,
                  width
                );
              }
              if (clinical.ui.tabs.currentPatientRecordTab) {
                clinical.ui.tabs.currentPatientRecordTab.sidePanelTabId =
                  selectedEncounter?.id;
                clinical.ui.tabs.currentPatientRecordTab.sidePanelTab =
                  TreeView.PastConsults;
              }
            }
          };
          break;
        case PatientSummaryEnum.ClinicalTools:
          newSummary.content = (
            <ClinicalToolCardComponent clinicalRecord={clinicalRecord} />
          );
          newSummary.onClickLink = () => {
            if (clinical.ui.tabs.currentPatientRecordTab)
              clinical.ui.tabs.currentPatientRecordTab.sidePanelTab =
                TreeView.ClinicalTools;
          };
          break;
        case PatientSummaryEnum.CommentsFlags:
          newSummary.content = (
            <PatientNoticesTable
              filter={NoticeType.Clinical}
              noDataMessage="No information to display"
              showNoDataTile
            />
          );
          newSummary.callout = isViewOnly ? null : <PatientNoticeCallout />;
          break;
        case PatientSummaryEnum.RecentInvestigationsDocument:
          newSummary.columnSpan = 2;
          newSummary.counter = (
            <DataFetcher
              refetchId={correspondence.editInboxRefreshKey}
              fetch={async () => {
                return await searchIncomingDocs(
                  clinicalRecord.id,
                  lastConsultDate
                );
              }}
              fallback={<Spinner />}
            >
              {value => {
                return !!value && <Text>({value.length ?? 0})</Text>;
              }}
            </DataFetcher>
          );
          newSummary.content = (
            <RecentIncomingDocComponent
              clinicalRecord={clinicalRecord}
              lastConsultDate={lastConsultDate}
            />
          );
          newSummary.isDisableLink = true;

          break;
        default:
      }

      const { ...rest } = newSummary;

      return (
        <PatientSummaryCard
          cardInfo={{
            ...rest
          }}
        />
      );
    };

    const patientSummaryPages =
      userExperience.settings.patientSummaryPages ??
      userExperience.ref.patientSummaries.values.map(
        ({ code, isChecked, permission }) => ({
          key: code,
          checked: isChecked,
          position: 0,
          permission
        })
      );

    const cardInfo = patientSummaryPages.filter((i: PatientSummaryCardInfo) => {
      return (
        i.checked && (i.permission ? core.hasPermissions(i.permission) : true)
      );
    }).map!((summary: PatientSummaryCardInfo) => getContent(summary));

    const patientLabel = userExperience.localisedConfig("patientDisplay", {
      capitalizeFirst: true
    });

    return (
      <Stack
        {...dataAttribute(DataAttributes.Element, "patient-summary")}
        tokens={{ childrenGap: 8 }}
        styles={{ root: { height: "inherit" } }}
      >
        <Stack.Item>
          <Heading variant="section-sub-heading">
            {patientLabel} summary
          </Heading>
        </Stack.Item>
        <Stack.Item styles={{ root: { padding: 0, overflowY: "auto" } }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(310px, 1fr))",
              gridGap: 24
            }}
          >
            {cardInfo}
          </div>
        </Stack.Item>
      </Stack>
    );
  }
);

const PatientSummary = withFetch(
  x => x.userExperience.ref.patientSummaries.load(),
  PatientSummaryBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PatientSummary;
