import React, { useState } from "react";

import { IColumn, PivotItem, PivotTabs, Stack, useTheme } from "@bps/fluent-ui";
import { ClinicalDataTypeLabel } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import {
  generateMeasurementColumns,
  reverseDateColumns
} from "./generateMeasurementColumns.tsx";
import { ObservationGraphView } from "./ObservationGraphView.tsx";
import { ObservationMatrixPivotBar } from "./ObservationMatrixPivotBar.tsx";
import { ObservationsHeaderWrapper } from "./ObservationsHeaderWrapper.tsx";
import {
  generateMeasurementRows,
  getMeasurementTypeLabel,
  groupMeasurementsByDateAndType
} from "./utils.ts";

interface ObservationsProps {
  clinicalRecord: ClinicalRecord;
}

export interface MeasurementRow {
  key: string;
  type: string;
  [key: string]: string;
}

export enum ObservationPivotName {
  Matrix = "Matrix",
  DashBoard = "Dashboard",
  All = "All"
}

export const ObservationsExpandedTable: React.FC<ObservationsProps> = ({
  clinicalRecord
}) => {
  const { clinical } = useStores();
  const theme = useTheme();
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [selectedKey, setSelectedKey] = useState<string>(
    ObservationPivotName.Matrix
  );

  const [switchDateOrder, setSwitchDateOrder] = useState<boolean>(false);

  const handlePivotItemClick = (item?: any) => {
    if (item?.props.itemKey) {
      setSelectedKey(item.props.itemKey);
    }
  };

  const fetchMeasurements = async () => {
    const allMeasurementsResult = await clinicalRecord.loadMeasurementData();
    const measurements = allMeasurementsResult.results;
    const groupedByDateAndType = groupMeasurementsByDateAndType(
      measurements,
      getMeasurementTypeLabel
    );

    const dates = Object.keys(groupedByDateAndType);

    const uniqueTypes = new Set(
      measurements.map(m => getMeasurementTypeLabel(m.type))
    );

    const sortedRows = generateMeasurementRows(
      uniqueTypes,
      dates,
      groupedByDateAndType
    );

    const rowsWithLabels = sortedRows.map(row => ({
      ...row,
      typeLabel: getMeasurementTypeLabel(row.type)
    }));

    const columns = generateMeasurementColumns({
      dates,
      groupedByDateAndType,
      theme
    });

    setColumns(columns);
    return rowsWithLabels;
  };

  return (
    <ObservationsHeaderWrapper
      heading={ClinicalDataTypeLabel.Observations}
      onClose={clinical.ui.tabs.currentPatientRecordTab!.hideActive}
      pivotTab={
        <PivotTabs selectedKey={selectedKey} onLinkClick={handlePivotItemClick}>
          <PivotItem
            headerText={ObservationPivotName.Matrix}
            itemKey={ObservationPivotName.Matrix}
          />
          <PivotItem
            headerText={ObservationPivotName.DashBoard}
            itemKey={ObservationPivotName.DashBoard}
          />
        </PivotTabs>
      }
    >
      {(selectedKey === ObservationPivotName.Matrix ||
        selectedKey === ObservationPivotName.All) && (
        <DataFetcher fetch={fetchMeasurements} noExceptionsHandlers>
          {(rows, loading, error) => {
            return loading || error || rows?.length ? (
              <Stack>
                <ObservationMatrixPivotBar
                  onSwitch={() => {
                    setSwitchDateOrder(!switchDateOrder);
                  }}
                  onPivotClick={handlePivotItemClick}
                  selectedKey={selectedKey}
                />
                <ShimmeredDetailsList
                  errorMessage={error?.message}
                  enableShimmer={loading}
                  columns={
                    switchDateOrder ? reverseDateColumns(columns) : columns
                  }
                  items={rows || []}
                />
              </Stack>
            ) : null;
          }}
        </DataFetcher>
      )}

      {selectedKey === ObservationPivotName.DashBoard && (
        <ObservationGraphView clinicalRecord={clinicalRecord} />
      )}
    </ObservationsHeaderWrapper>
  );
};
