import { observer } from "mobx-react-lite";
import { FunctionComponent, useCallback, useContext } from "react";

import {
  CheckboxVisibility,
  IDetailsRowProps,
  SelectionMode
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { AccInvoiceCalendarEventCell } from "@modules/billing/screens/acc-invoices/components/AccInvoiceCalendarEventCell.tsx";
import { IInvoiceRow } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsList.tsx";
import { InvoiceRow } from "@modules/billing/screens/acc-invoices/components/InvoiceRow.tsx";
import {
  appointmentColumn,
  claimColumn,
  descriptionColumn,
  insurerColumn,
  invoiceColumn,
  patientColumn,
  paymentColumn,
  providerColumn,
  sentColumn,
  servicedColumn,
  SortableColumnIds,
  stateColumn,
  valueColumn
} from "@modules/billing/screens/acc-invoices/components/InvoiceRowColumns.ts";
import { TransactionClaimCell } from "@modules/billing/screens/acc-invoices/components/TransactionClaimCell.tsx";
import { TransactionInvoiceCell } from "@modules/billing/screens/acc-invoices/components/TransactionInvoiceCell.tsx";
import { TransactionPatientCell } from "@modules/billing/screens/acc-invoices/components/TransactionPatientCell.tsx";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { InvoiceItemDescriptionCell } from "@modules/billing/screens/account/components/transaction-list/InvoiceItemDescriptionCell.tsx";
import { InvoiceItemValueCell } from "@modules/billing/screens/account/components/transaction-list/InvoiceItemValueCell.tsx";
import { TransactionProvider } from "@modules/billing/screens/account/components/TransactionProvider.tsx";
import { BillingListNoDataTile } from "@modules/billing/screens/billing-history/components/BillingListNoDataTile.tsx";
import { useTransactionFilterContext } from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/context/TransactionFilterContext.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { InfiniteScrollListColumn } from "@ui-components/InfiniteScrollList/InfiniteScrollList.types.ts";

import { AccreditedBillingInsurerCell } from "./AccreditedBillingInsurerCell.tsx";
import { AccreditedBillingSentDateCell } from "./AccreditedBillingSentDateCell.tsx";
import { AccreditedBillingTransactionAllocationCell } from "./AccreditedBillingTransactionAllocationCell.tsx";
import { AccreditedBillingTransactionStateCell } from "./AccreditedBillingTransactionStateCell.tsx";

interface AccreditedBillingListProps {
  insurerIds: string[];
}

export const AccreditedBillingList: FunctionComponent<AccreditedBillingListProps> =
  observer(({ insurerIds }) => {
    const { routing, billing, core } = useStores();
    const { getTransactionArgs } = useTransactionFilterContext();
    const helper = useContext(AccInvoicesListContext);
    const getItems = useCallback(
      async (query: PagingOptions): Promise<QueryResult<Invoice>> => {
        if (insurerIds.length === 0) {
          // if no insurers return no results
          return { results: [], skip: 0, take: 25 };
        }

        const searchArgs = getTransactionArgs(query);
        const result = await helper.search(searchArgs);

        helper.setTotalInvoices(result);

        return result;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [helper, insurerIds, routing.location.search, getTransactionArgs]
    );

    const onRenderRow = (
      props: IDetailsRowProps | undefined,
      defaultRender:
        | ((props?: IDetailsRowProps | undefined) => JSX.Element | null)
        | undefined
    ) => {
      if (!props || !defaultRender) {
        return null;
      }

      const invoice = props.item as Invoice;

      return (
        <InvoiceRow
          props={props}
          defaultRender={defaultRender}
          invoice={invoice}
        />
      );
    };

    const columnArr: InfiniteScrollListColumn[] = [
      {
        ...invoiceColumn,
        onRender: (props: IInvoiceRow) => <TransactionInvoiceCell {...props} />
      },

      {
        ...insurerColumn,
        onRender: (props: IInvoiceRow) => (
          <AccreditedBillingInsurerCell {...props} />
        )
      },
      {
        ...stateColumn,
        onRender: (props: IInvoiceRow) => (
          <AccreditedBillingTransactionStateCell {...props} />
        )
      },
      {
        ...sentColumn,
        onRender: (props: IInvoiceRow) => (
          <AccreditedBillingSentDateCell {...props} />
        )
      },
      {
        ...paymentColumn,
        onRender: (props: IInvoiceRow) => (
          <AccreditedBillingTransactionAllocationCell {...props} />
        )
      },
      {
        ...valueColumn,
        onRender: (props: IInvoiceRow) => (
          <InvoiceItemValueCell amount={props.invoiceItem.amount} />
        )
      },
      {
        ...descriptionColumn,
        onRender: (props: IInvoiceRow) => (
          <InvoiceItemDescriptionCell {...props} />
        )
      },
      {
        ...servicedColumn,
        onRender: (props: IInvoiceRow) => (
          <>
            {DateTime.fromISO(
              props.invoiceItem.serviceDate
            ).toDayDefaultFormat()}
          </>
        )
      },
      {
        ...patientColumn,
        onRender: (props: IInvoiceRow) => <TransactionPatientCell {...props} />
      },
      {
        ...claimColumn,
        onRender: (props: IInvoiceRow) =>
          props.isFirstItem ? <TransactionClaimCell {...props} /> : null
      },
      {
        ...appointmentColumn,
        onRender: (props: IInvoiceRow) =>
          props.isFirstItem ? <AccInvoiceCalendarEventCell {...props} /> : null
      },
      {
        ...providerColumn,
        onRender: (props: IInvoiceRow) =>
          props.isFirstItem ? <TransactionProvider {...props} /> : null
      }
    ];

    return (
      <InfiniteScrollList<Invoice>
        getItems={getItems}
        onRenderNoResults={() => <BillingListNoDataTile />}
        selectionMode={SelectionMode.multiple}
        selection={helper.selection}
        displayDefaultCheckBox
        checkboxAutomationAttribute="accredited-billing-item-checkbox"
        checkboxVisibility={CheckboxVisibility.always}
        onRenderRow={onRenderRow}
        columns={
          core.hasPermissions(Permission.AppointmentTypeRead)
            ? columnArr
            : columnArr.filter(column => column.key !== "appointment")
        }
        initialSort={{
          sortColumn: SortableColumnIds.invoiceNumber,
          sortDescending: true
        }}
        refreshKey={billing.ui.lastUpdatedInvoiceETag}
      />
    );
  });
