import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import { useCallback, useContext } from "react";

import {
  CheckboxVisibility,
  dataAttribute,
  DataAttributes,
  IColumn,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  SelectionMode,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { ClaimAdjustmentCallout } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentCallout.tsx";
import { isClaimAdjustmentAllowed } from "@modules/acc/screens/claim-adjustment/components/utils.ts";
import { ClaimsScreenContext } from "@modules/acc/screens/claims/context/ClaimsScreenContext.ts";
import { ClaimStatusBadge } from "@modules/acc/screens/shared-components/claim-status-badge/ClaimStatusBadge.tsx";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { ClaimDocumentViewerDialog } from "./ClaimDocumentViewerDialog.tsx";
import { ClaimNumberCell } from "./ClaimNumberCell.tsx";
import { ClaimsContextualMenu } from "./ClaimsContextualMenu.tsx";
import { ClaimsFilterQuery } from "./ClaimsFilter.tsx";
import { ClaimsListInjuryDiagnosisText } from "./ClaimsListInjuryDiagnosisText.tsx";

export const claimsActionRequiredStatuses = [
  ClaimStatuses.Accredited,
  ClaimStatuses.Declined,
  ClaimStatuses.Error,
  ClaimStatuses.Incomplete,
  ClaimStatuses.NotVerified,
  ClaimStatuses.Ready
];

export const ClaimsListBase = observer(() => {
  const { selection } = useContext(ClaimsScreenContext);
  const { acc, routing } = useStores();
  const theme = useTheme();

  const search = useCallback(
    async (query: PagingOptions): Promise<QueryResult<Claim>> => {
      const searchQuery = routing.location.search;

      const filter: ClaimsFilterQuery = searchQuery
        ? parse(searchQuery, {
            parseBooleans: true
          })
        : {};
      return acc.fetchClaims({
        ...query,
        ...filter,
        claimStatusCode: filter.claimsActionRequired
          ? claimsActionRequiredStatuses
          : filter.claimStatusCode,
        excludePrivate: filter.claimsActionRequired
      });
    },
    [acc, routing.location.search]
  );

  const searchString = routing.queryStringParam("claimNumber") || "";
  const claimNumberSearch = parse(routing.location.search).claimNumber;

  const columns: IColumn[] = [
    {
      name: "Actions",
      className: RESET_CELLS_PADDING_CLASSNAME,
      key: "action",
      minWidth: 50,
      maxWidth: 50,
      onRender: (claim: Claim) => <ClaimsContextualMenu claim={claim} />
    },
    {
      name: "Status",
      key: "claimStatus",
      className: RESET_CELLS_PADDING_CLASSNAME,
      minWidth: 120,
      maxWidth: 120,
      onRender: (claim: Claim) => (
        <ClaimStatusBadge
          styles={{
            root: {
              horizontalAlign: "start",
              styles: { root: { marginLeft: 12 } }
            }
          }}
          claimStatus={claim.claimStatus}
        />
      )
    },
    {
      name: "Claim",
      key: "claimNumber",
      minWidth: 80,
      maxWidth: 100,
      onRender: (claim: Claim) => (
        <ClaimNumberCell claim={claim} filter={searchString} />
      )
    },
    {
      name: "Patient",
      key: "name",
      minWidth: 100,
      maxWidth: 200,
      onRender: (claim: Claim) =>
        claim.patientId && (
          <Navigate
            {...dataAttribute(DataAttributes.Element, "patient-Name")}
            to={routes.contacts.contact.path({
              id: claim.patientId
            })}
          >
            {claim.patientFullName}
          </Navigate>
        )
    },
    {
      name: "Diagnosis",
      key: "injuryDiagnosis",
      minWidth: 100,
      maxWidth: 150,
      onRender: (claim: Claim) => (
        <Stack>
          <Text nowrap>
            {!!claim.currentDiagnoses && claim.currentDiagnoses.length > 0 && (
              <ClaimsListInjuryDiagnosisText
                diagnosis={claim.currentDiagnoses}
              />
            )}
          </Text>
        </Stack>
      )
    },
    {
      name: "Provider",
      key: "provider",
      minWidth: 100,
      maxWidth: 200,
      onRender: (claim: Claim) =>
        claim.providerFullName && <Text>{claim.providerFullName}</Text>
    },
    {
      name: "Initial consult",
      key: "initialConsultDate",
      minWidth: 80,
      maxWidth: 100,
      onRender: (claim: Claim) =>
        !!claim.initialConsultDate &&
        claim.initialConsultDate.toDayDefaultFormat()
    },
    {
      name: "Insurer",
      key: "insurerName",
      minWidth: 100,
      maxWidth: 200,
      onRender: (claim: Claim) =>
        claim.insurerName && <Text>{claim.insurerName}</Text>
    }
  ];

  columns.splice(7, 0, {
    name: "ACC32",
    key: "claimAdjustments",
    minWidth: 120,
    maxWidth: 120,
    onRender: (claim: Claim) => {
      if (
        claim.claimAdjustments.length > 1 &&
        isClaimAdjustmentAllowed(claim.claimStatus ?? "")
      ) {
        return <ClaimAdjustmentCallout claim={claim} />;
      } else if (claim.claimAdjustments?.length) {
        return (
          <Navigate
            to={routes.claimAdjustment.edit.path({
              id: claim.claimAdjustments[0].id,
              claimId: claim.id
            })}
          >
            {claim.claimAdjustments[0].formattedNumber}
          </Navigate>
        );
      }
      return;
    }
  });

  return (
    <>
      <div style={{ position: "relative", flexGrow: 1 }}>
        <ScrollablePane
          styles={{
            root: {
              height: "100%"
            }
          }}
        >
          <InfiniteScrollList<Claim>
            onRenderNoResults={() => (
              <DefaultNoResultsTile
                defaultText={
                  claimNumberSearch
                    ? `No matches found for "${claimNumberSearch}"`
                    : undefined
                }
              />
            )}
            setKey="claims-list"
            refreshKey={acc.ui.recentlyUpdatedClaimKey}
            getItems={search}
            selectionMode={SelectionMode.multiple}
            selection={selection}
            displayDefaultCheckBox
            checkboxAutomationAttribute="claim-list-item-checkbox"
            checkboxVisibility={CheckboxVisibility.always}
            onRenderRow={(props, defaultRender) => {
              if (!props || !defaultRender) {
                return null;
              }
              return defaultRender!({
                ...props,
                styles: {
                  root: {
                    "&.is-selected:hover": {
                      backgroundColor:
                        theme.semanticColors.listItemBackgroundChecked
                    }
                  }
                }
              });
            }}
            columns={columns}
          />
        </ScrollablePane>
      </div>
      <ClaimDocumentViewerDialog />
    </>
  );
});

export const ClaimsList = withFetch(
  x => [
    x.acc.ref.accidentLocations.load(),
    x.acc.ref.accidentScenes.load(),
    x.acc.ref.occupations.load(),
    x.acc.ref.sports.load()
  ],
  ClaimsListBase
);
