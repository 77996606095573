import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { dataAttribute, DataAttributes, Link } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  MeasurementDto,
  QuestionnaireItem
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { Rand36ScoreModal } from "./Rand36ScoreModal.tsx";

export interface Rand36ClinicalToolListItemProps {
  measurement: MeasurementDto;
  clinicalRecord: ClinicalRecord;
}

export const Rand36ClinicalToolListItem: React.FC<Rand36ClinicalToolListItemProps> =
  observer(({ measurement, clinicalRecord }) => {
    const [measurements, setMeasurements] = useState<MeasurementDto[]>([]);
    const [answers, setAnswers] = useState<QuestionnaireItem[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    const { clinical } = useStores();

    const onClose = () => {
      setShowModal(false);
    };

    const onOutcomeClicked = async () => {
      const relatedMeasurements = clinicalRecord.measurements.filter(
        x =>
          x.encounterId === measurement.encounterId &&
          x.type === measurement.type
      );

      const clinicalDataResult = await clinical.getEncounterClinicalData({
        encounterId: measurement.encounterId,
        types: [ClinicalDataType.RAND36]
      });
      if (clinicalDataResult) {
        const values = clinicalDataResult.rand36?.items;

        if (values) {
          setMeasurements(relatedMeasurements);
          setAnswers(values);
          setShowModal(true);
        }
      }
    };

    return (
      <>
        <Link
          {...dataAttribute(DataAttributes.Element, "open-result-link")}
          onClick={() => {
            onOutcomeClicked();
          }}
        >
          Outcome
        </Link>

        {showModal && (
          <Rand36ScoreModal
            measurements={measurements}
            answers={answers}
            onClose={onClose}
          />
        )}
      </>
    );
  });
