import { LocationsSelectField } from "@modules/practice/screens/shared-components/location/LocationsSelectField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";

export const InvoiceLocationSelectField: React.FC = () => {
  const { core, booking } = useStores();
  const fetchUsersOrgUnits = async (userId: string) => {
    const user = await core.userMap.get(userId);
    if (user) {
      return await booking.getUserAvailability(user?.id);
    }
    return undefined;
  };

  return (
    <FieldSpy
      name={invoiceFormNameOf("userId")}
      subscription={{ initial: true, value: true }}
    >
      {(_state, userId) => (
        <DataFetcher
          fetch={() => fetchUsersOrgUnits(userId)}
          refetchId={userId}
        >
          {userOrgUnits => (
            <LocationsSelectField
              fieldItemStyle={{
                root: { flexGrow: 1, flexBasis: 0, minWidth: 0 }
              }}
              hideClearButton={true}
              name={invoiceFormNameOf("locationId")}
              placeholder="Select location"
              label="Location"
              required
              showIcon={false}
              hideSearchOption
              showAllSelected={false}
              multiSelect={false}
              disabled={
                !userId || userOrgUnits?.availableOrgUnitIds.length === 1
              }
              onFilter={options =>
                options.filter(
                  option =>
                    userOrgUnits &&
                    userOrgUnits.availableOrgUnitIds.includes(option.key)
                )
              }
            />
          )}
        </DataFetcher>
      )}
    </FieldSpy>
  );
};
