import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import { DefaultButton, Stack } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { SelectedInvoicesMessageBar } from "./SelectedInvoicesMessageBar.tsx";

export const SelectedInvoicesGroup: React.FC = observer(() => {
  const { routing } = useStores();

  const {
    defaultNzPublicInsurerId,
    selection,
    selectedInvoices,
    disableBulkSubmit,
    bulkSubmitHandler
  } = useContext(AccInvoicesListContext);

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const onClickSubmit = () => {
    setShowConfirmDialog(true);
  };

  const onClickConfirm = async () => {
    await bulkSubmitHandler();
    setShowConfirmDialog(false);
    selection.setAllSelected(false);
  };

  const onCancelConfirm = () => {
    setShowConfirmDialog(false);
  };

  const onNewPaymentClick = () => {
    routing.push(
      {
        pathname: routes.accounts.allocations.new.path({}),
        search: `?accountId=${defaultNzPublicInsurerId}`
      },
      {
        ...routing.getStateWithFromQuery(),
        invoiceIds: selectedInvoices.map(invoice => invoice.id).join(",")
      }
    );
  };

  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
      <SelectedInvoicesMessageBar />
      <Stack.Item>
        <DefaultButton
          disabled={!selectedInvoices.length}
          onClick={onNewPaymentClick}
        >
          New payment
        </DefaultButton>
      </Stack.Item>
      <Stack.Item>
        <DefaultButton disabled={disableBulkSubmit} onClick={onClickSubmit}>
          Submit
        </DefaultButton>
        {showConfirmDialog && (
          <SubmissionFormDialog
            dialogName="Selected Invoices"
            onSubmit={onClickConfirm}
            dialogProps={{
              onDismiss: onCancelConfirm,
              dialogContentProps: { title: "Submit?" }
            }}
            buttonsProps={{
              submitButtonProps: {
                text: "Submit",
                iconProps: { hidden: true }
              },
              disableSubmitOnPristine: false
            }}
          >
            {() => (
              <>
                <Stack.Item>
                  The following invoice(s) will be sent to ACC:
                </Stack.Item>
                <Stack styles={{ root: { maxHeight: 140, overflowY: "auto" } }}>
                  {selectedInvoices.map(invoice => (
                    <Stack.Item key={invoice.id}>{invoice.number}</Stack.Item>
                  ))}
                </Stack>
              </>
            )}
          </SubmissionFormDialog>
        )}
      </Stack.Item>
    </Stack>
  );
});
