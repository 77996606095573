import React, { useEffect } from "react";

import { Card, Spinner, Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { useScrollToViewById } from "@ui-components/hooks/useScrollToViewById.tsx";
import { PageLink } from "@ui-components/PageLink/PageLink.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { SettingsLabels } from "../shared-components/SettingsLabels.ts";
import { AppointmentCancellationReasonList } from "./components/appointment-cancellation/AppointmentCancellationReasonList.tsx";
import { AppointmentSettingsCard } from "./components/appointment-settings/AppointmentSettingsCard.tsx";
import { InvoiceSettingsCard } from "./components/billing-settings/InvoiceSettingsCard.tsx";
import { CommunicationSettingsCard } from "./components/communications/CommunicationSettingsCard.tsx";
import { DocumentSettingsCard } from "./components/document-settings/DocumentSettingsCard.tsx";
import { AccSetupForm } from "./components/health-certificate/AccSetupForm.tsx";
import HealthCertificateUpload from "./components/health-certificate/HealthCertificateUpload.tsx";
import { LocationDetailsForm } from "./components/location-details/LocationDetailsForm.tsx";
import {
  OrgUnits,
  PracticeDetailsForm
} from "./components/practice-details/PracticeDetailsForm.tsx";
import { SecurityForm } from "./components/practice-security/SecurityForm.tsx";
import { SecurityFormContext } from "./components/practice-security/SecurityFormContext.tsx";
import { SecurityFormModel } from "./components/practice-security/SecurityFormModel.ts";
import { PreferenceSettingForm } from "./components/preference-setting/PreferenceSettingForm.tsx";

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 0
      }
    }
  }
};

export const PracticeScreenBase: React.FC = React.memo(() => {
  const { routing, core, userExperience } = useStores();
  const scroll = useScrollToViewById();

  useEffect(() => {
    scroll(routing.location.hash.replace("#", ""));
  }, [routing.location.hash, scroll]);

  const getOrgUnits = async ({
    core,
    billing,
    practice,
    userExperience
  }: RootStore): Promise<OrgUnits | undefined> => {
    if (core.location.parentOrgUnit) {
      const [pracOrgUnit, orgUnitSetting, invoiceSettings] = await Promise.all([
        practice.getOrgUnit(core.location.parentOrgUnit.id),
        userExperience.getOrgUnitSetting(core.location.parentOrgUnit.id),
        core.hasPermissions(Permission.FinancialSettingsWrite)
          ? billing.getInvoiceSettings()
          : []
      ]);

      return {
        pracOrgUnit,
        orgUnitSetting,
        invoiceSetting: invoiceSettings.length ? invoiceSettings[0] : undefined
      };
    }
    return undefined;
  };

  const securityFormModel = new SecurityFormModel(userExperience);

  return (
    <Stack tokens={{ childrenGap: 24 }}>
      <Card
        iconName="Hospital"
        headingLevel="section-heading"
        heading={SettingsLabels.practiceDetails}
        styles={cardStyles}
      >
        {/* Moved DataFetchers on the level above resolves the issue with scroll to particular section
          previously forms were not ready for scroll immediately as loading refData and fetching necessary data takes time
          now everything is preloaded and forms ready for scroll */}
        <DataFetcher fetch={getOrgUnits} fallback={<Spinner />}>
          {(orgUnits: OrgUnits | undefined) => (
            <PracticeDetailsForm orgUnits={orgUnits} />
          )}
        </DataFetcher>
      </Card>
      {!core.hasMultiLocationOrgUnit && (
        <Card
          id="location-details"
          iconName="Nav2DMapView"
          headingLevel="section-heading"
          heading={SettingsLabels.locationDetails}
          styles={cardStyles}
        >
          <WhenCountry is={Country.NewZealand} else={<LocationDetailsForm />}>
            <DataFetcher
              fetch={({ practice }) => practice.getOrgUnit(core.location.id)}
              fallback={<Spinner />}
            >
              {(pracOrgUnit: PracOrgUnit) => (
                <LocationDetailsForm pracOrgUnit={pracOrgUnit} />
              )}
            </DataFetcher>
          </WhenCountry>
        </Card>
      )}
      <When permission={Permission.OrgUnitSettingWrite}>
        <Card
          iconName="BpPreferences"
          headingLevel="section-heading"
          heading={SettingsLabels.preferenceSetting}
          styles={cardStyles}
        >
          <PreferenceSettingForm />
        </Card>
      </When>
      <When permission={Permission.InactivityTimeoutSettingsAllowed}>
        <Card
          iconName="permissions"
          headingLevel="section-heading"
          heading={SettingsLabels.security}
          styles={cardStyles}
        >
          <SecurityFormContext.Provider value={securityFormModel}>
            <SecurityForm />
          </SecurityFormContext.Provider>
        </Card>
      </When>

      <WhenCountry is={Country.NewZealand}>
        <Card
          iconName="Certificate"
          headingLevel="section-heading"
          heading={SettingsLabels.accSetup}
          styles={cardStyles}
        >
          <DataFetcher fetch={getOrgUnits} fallback={<Spinner />}>
            {(orgUnits: OrgUnits | undefined) => (
              <AccSetupForm orgUnits={orgUnits}>
                <HealthCertificateUpload />
              </AccSetupForm>
            )}
          </DataFetcher>
        </Card>
      </WhenCountry>
      <When
        permission={[
          Permission.ApptTypeBaseIntervalAllowed,
          Permission.OrgUnitSettingWrite
        ]}
        permissionOperator="and"
      >
        <AppointmentSettingsCard />
      </When>
      <Card
        iconName="calendar"
        headingLevel="section-heading"
        heading={SettingsLabels.appointments}
        styles={cardStyles}
      >
        {core.hasPermissions(Permission.CalendarEventRead) && (
          <AppointmentCancellationReasonList />
        )}
      </Card>

      <PageLink
        onClick={() =>
          routing.push(routes.settings.practices.openingHours.path({}))
        }
        headingText={SettingsLabels.openingHours}
        iconName="Door"
      />
      <PageLink
        onClick={() =>
          routing.push(routes.settings.practices.openingHoursOverrides.path({}))
        }
        headingText={SettingsLabels.exceptions}
        iconName="AwayStatus"
      />
      <CommunicationSettingsCard />

      <DataFetcher<PracOrgUnit | undefined>
        fetch={({ core, practice }) =>
          core.location.parentOrgUnit
            ? practice.getOrgUnit(core.location.parentOrgUnit.id)
            : Promise.resolve(undefined)
        }
        fallback={<Spinner />}
      >
        {pracOrgUnit => {
          return pracOrgUnit ? (
            <DocumentSettingsCard pracOrgUnit={pracOrgUnit} />
          ) : null;
        }}
      </DataFetcher>
      <InvoiceSettingsCard />
    </Stack>
  );
});

export const PracticeScreen = withFetch(
  x => [
    x.core.ref.australianStates.load(),
    x.core.ref.timeZones.load(),
    x.userExperience.ref.apptTypeBaseIntervals.load()
  ],
  PracticeScreenBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PracticeScreen;
