import { Field } from "react-final-form";

import {
  CommandButton,
  DirectionalHint,
  IComboBoxOption,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  NatureOfReaction,
  ReactionSeverity
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { NatureOfReactionFields } from "./ReactionForm.types.ts";
import { nameOfReactionForm } from "./ReactionFormFields.tsx";

interface NatureOfReactionsFieldsProps {
  onDeleteReaction: (doomed: NatureOfReactionFields) => void;
}

export const NatureOfReactionsFields: React.FC<
  NatureOfReactionsFieldsProps
> = ({ onDeleteReaction }) => {
  const { clinical } = useStores();
  const { fields: naturesOfReactions } =
    useFieldArray<NatureOfReactionFields>("naturesOfReaction");

  const onRenderNatureOfReactionsOption = (
    selectedKey: string,
    options: IComboBoxOption[]
  ) => {
    return options[selectedKey] ? options[selectedKey].text : selectedKey;
  };

  return (
    <div>
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
        <Text variant="large">Types of reaction</Text>
        <CommandButton
          text="Add more"
          title="Add a nature of reaction"
          iconProps={{
            iconName: "Add"
          }}
          onClick={() => {
            naturesOfReactions.push({});
          }}
        />
      </Stack>

      <Stack tokens={{ childrenGap: 16 }}>
        <FieldArray name={nameOfReactionForm("naturesOfReaction")}>
          {({ fields }) =>
            fields.value.map((currentNaturesOfReaction, index) => {
              const name = `${nameOfReactionForm(
                "naturesOfReaction"
              )}[${index}]`;

              return (
                <Stack
                  key={name}
                  horizontal
                  verticalAlign="baseline"
                  tokens={{ childrenGap: 16 }}
                >
                  <ComboBoxField
                    required
                    options={clinical.ref.natureOfReactions.keyTextValues}
                    allowFreeform
                    label={index === 0 ? "Nature of reaction" : undefined}
                    name={`${name}.natureOfReaction`}
                    fieldItemStyles={{ root: { width: "100%" } }}
                    calloutProps={{
                      directionalHint: DirectionalHint.bottomCenter,
                      calloutMaxHeight: 250
                    }}
                    placeholder="Select or insert free text"
                    onRenderFieldText={onRenderNatureOfReactionsOption}
                    useComboBoxAsMenuWidth
                  />
                  <Field<string | undefined>
                    name={`${name}.natureOfReaction`}
                    subscription={{ value: true }}
                  >
                    {({ input: { value: natureOfReaction } }) => (
                      <Stack.Item align="start">
                        <Stack horizontal verticalAlign="end">
                          <DropdownField
                            required={index === 0}
                            styles={{
                              root: {
                                width: 110,
                                alignSelf: "center"
                              },
                              errorMessage: { display: "none" }
                            }}
                            name={`${name}.severity`}
                            label={index === 0 ? "Severity" : undefined}
                            options={
                              clinical.ref.reactionSeverities.keyTextValues
                            }
                            disabled={
                              natureOfReaction === NatureOfReaction.Anaphylaxis
                            }
                          />
                          {naturesOfReactions.length &&
                            naturesOfReactions.length > 1 && (
                              <DeleteButton
                                text="Remove"
                                onClick={() =>
                                  onDeleteReaction(currentNaturesOfReaction)
                                }
                              />
                            )}
                        </Stack>
                      </Stack.Item>
                    )}
                  </Field>

                  <FieldSpy
                    name={`${name}.natureOfReaction`}
                    onChange={natureOfReaction => {
                      if (natureOfReaction === NatureOfReaction.Anaphylaxis) {
                        naturesOfReactions.update(index, {
                          ...currentNaturesOfReaction,
                          severity: ReactionSeverity.Severe
                        });
                      }
                    }}
                  />
                </Stack>
              );
            })
          }
        </FieldArray>
      </Stack>
    </div>
  );
};
