import { ReactionClinicalDataDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  Length,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  NatureOfReactionFields,
  ReactionFormTypes
} from "./ReactionForm.types.ts";
import { OTHER_CODE } from "./ReactionFormFields.tsx";
import { toReactionAgentType } from "./utils.ts";

export class ReactionFormValidator extends Validator<ReactionFormTypes> {
  constructor(context: {
    existingReactions?: ReactionClinicalDataDto;
    isAdd: boolean;
  }) {
    super();
    const isDuplicateReaction = (key: string) => {
      const [typeId] = key.split(".");

      if (typeId === OTHER_CODE) return false;
      return !!context.existingReactions?.reactions?.some(
        x => `${x.agent.code}.${toReactionAgentType(x.type)}` === key
      );
    };

    const isDuplicateOtherText = (otherText: string) => {
      return !!context.existingReactions?.reactions?.some(
        x => x.otherText?.toLocaleUpperCase() === otherText.toLocaleUpperCase()
      );
    };

    this.forField("reaction", [required()]);
    this.forField("certainty", [required()]);
    this.forField("otherText", [
      predicate((val, values) => values?.reaction === "Other", required()),
      val =>
        context.isAdd && val && isDuplicateOtherText(val)
          ? " Duplicate other text"
          : undefined
    ]);

    if (context.isAdd) {
      this.forField("reaction", [
        (value: string | undefined) =>
          value && isDuplicateReaction(value)
            ? "This agent has already been recorded for this patient."
            : undefined
      ]);
    }
    this.forArrayField(
      "naturesOfReaction",
      new NatureOfReactionFieldsValidator().validate
    );
    this.forField("comment", maxLength(Length.long));
  }
}

class NatureOfReactionFieldsValidator extends Validator<
  NatureOfReactionFields,
  ReactionFormTypes
> {
  constructor() {
    super();
    this.forField("severity", [required()]);
    this.forField("natureOfReaction", [required()]);
    this.forField(
      "natureOfReaction",
      (natureOfReaction, parent: NatureOfReactionFields, root) => {
        const natures = root.naturesOfReaction
          .filter(x => !!x.natureOfReaction)
          .map(x => x.natureOfReaction);

        const isDuplicate =
          natures.filter(x => x === natureOfReaction).length > 1;
        return isDuplicate ? "Duplicate nature of reaction." : undefined;
      },
      {
        when: value => !!value
      }
    );
  }
}
